import React, { useState } from "react"

import Layout from "../../components/Layout"
import RibbonText from "../../components/ui/RibbonText"
import ContactBanner from "../../components/ContactBanner"
import Seo from "../../components/Seo"

export default function Faq() {
  const faqs1 = [
    {
      question: "Do you charge a fee?",
      answer:
        "We charge a fully refundable engagement fee of $750. If you proceed to use the services of a builder that we have introduced you to we will fully refund the fee when you sign a building contract with a Builder Finders builder. ",
    },
    {
      question:
        "We are already dealing with a Sydney Home builder or Broker, can we talk to you?",
      answer:
        "Yes, you can, a lot of our clients have engaged a builder before coming to us and walked away from them extremely frustrated. You are welcome to speak to any of our clients to confirm how we have helped them and saved them from nightmare situations.",
    },
    {
      question:
        "What quality control measures are in place with your builders?",
      answer:
        "Every Builder Finders project includes a practical completion inspection by an independent registered builder upon completion of the home as part of our standard process.",
    },
    {
      question:
        "Can we choose different suppliers to the builder and select or supply our own items?",
      answer:
        "Yes, your Builder Finders Consultant will highlight which builders have the flexibility to choose your own suppliers.",
    },
    {
      question:
        "We want to engage our own designer and/or architect, can we bring the design to you?",
      answer:
        "Yes, we are always happy to give your plans to three builders to quote on your design.",
    },
    {
      question: "Do you do property developments?",
      answer: "Yes, we do property developments.",
    },
  ]
  const faqs2 = [
    {
      question: "How does Builder Finders get paid?",
      answer:
        "Builder Finders gets paid a commission by the builder for each building contract they sign with a Builder Finders client. ",
    },
    {
      question: "How can Builder Finders help me choose a builder?",
      answer:
        "We review all builders to ensure they meet Builder Finders’ criteria and are capable of building the project and delivering on all outcomes set out in the brief. With over 20 builders, Builder Finders can find a builder who is the right fit for you and your project, who together with Builder Finders will guide you through the process from start to finish.",
    },
    {
      question:
        "Why does Builder Finders have so many builders and design solutions on their books?",
      answer:
        "More choice means more competition, which ultimately means more competitively priced building quotes. Certain builders can bring their own designs solutions, unique skill sets and valuable experience to specialised builds like narrow or sloping lots of land. It’s how we manage to get the right fit for each of our clients.",
    },
    {
      question: "How much can you save me?",
      answer:
        "This depends on your personal situation. However, our unique tendering process has shown to save you up to 5-10%.",
    },
    {
      question: "Can I choose my own tradesmen?",
      answer:
        "Absolutely, a number of our past clients have chosen to do so. We will find builders that are happy to work with your tradesman.",
    },
    {
      question: "If we are not happy, can we walk away with no obligation?",
      answer:
        "Yes, from Builder Finders. Yes from the builder provided a building contract has not been signed.",
    },
  ]

  return (
    <Layout>
      <Seo title="FAQs" />
      <section className="wrapper py-8 sm:py-10 md:py-16">
        <div className="container flex flex-col space-y-6 md:space-y-12">
          <h1>FAQs</h1>
          <RibbonText
            text="Frequently asked"
            highlighted="questions"
            justify="left"
          />
          <div className="flex flex-col sm:flex-row sm:space-x-20">
            <div className="flex flex-col sm:w-1/2">
              {faqs1.map(faq => (
                <Accordion question={faq.question} answer={faq.answer} />
              ))}
            </div>
            <div className="flex flex-col sm:w-1/2">
              {faqs2.map(faq => (
                <Accordion question={faq.question} answer={faq.answer} />
              ))}
            </div>
          </div>
        </div>
      </section>
      <ContactBanner />
    </Layout>
  )
}

function Accordion({ question, answer }) {
  const [open, setOpen] = useState(false)
  const toggle = () => {
    setOpen(!open)
  }

  return (
    <div className="flex flex-col py-4 border-b-4 border-opacity-50 border-secondary">
      <div
        className="flex flex-row h-10 items-center space-x-4 cursor-pointer"
        onClick={toggle}
        role="button"
        tabIndex={0}
      >
        <div className="relative flex flex-col w-4 h-4 justify-center items-center">
          <div className="w-1 h-4 bg-tertiary" />
          <div className="absolute w-4 h-1 bg-tertiary" />
        </div>
        <p className="font-bold">{question}</p>
      </div>
      <div
        className={
          "overflow-hidden transition-all duration-100 " +
          (open ? "pt-4 max-h-80" : "pt-0 max-h-0")
        }
      >
        <p>{answer}</p>
      </div>
    </div>
  )
}
